import React from 'react';
import styled from 'styled-components';
import HubspotForm from './HubSpotForm';

const BlockContainer = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px 10px;
  position: relative;
  background-color: #051025;
  z-index: 3;
`;

const Line = styled.div`
  flex-basis: 100%;
  height: 2px;
  background-color: white;
  opacity: 0.5;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: space-between;
  margin: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Icon = styled.div`
  margin-right: 5px;
`;

const Text = styled.div`
  font-size: 14px;
`;

const CopyRight = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const AddressIcon = styled.img`
  margin-right: 5px;
`;

const AddressText = styled.div`
  font-size: 14px;
`;
const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 10px;
  flex-direction: column;
  @media(max-width: 1024px){
    align-items: center;
  }
`;

const Separator = styled.span`
  margin: 0 10px;
  color: #999;
`;

const TermsPrivacyContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  margin: 10px;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25%;
  @media (max-width: 1024px) {
    width: 45%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const NewFooter = () => (
  <BlockContainer>
    <Line />
    <IconContainer>
      <IconsWrapper>
        <a
          style={{ display: 'flex', flexDirection: 'row', color: 'white' }}
          href="https://www.linkedin.com/company/uptick-co"
        >
          <Icon>
            <img src="./assets/linkedin_icon.png" alt="linkedin" />
          </Icon>
          <Text>LinkedIn</Text>
        </a>
        <a
          style={{ display: 'flex', flexDirection: 'row', color: 'white' }}
          href="https://www.twitter.com/joinuptick"
        >
          <Icon>
            <img src="./assets/twitter_icon.png" alt="twitter" />
          </Icon>
          <Text>Twitter</Text>
        </a>
        <a
          style={{ display: 'flex', flexDirection: 'row', color: 'white' }}
          href="https://t.me/joinchat/Si9tndmlQSExNjDN"
        >
          <Icon>
            <img src="./assets/telegram_icon.png" alt="telegram" />
          </Icon>
          <Text>Telegram</Text>
        </a>
      </IconsWrapper>
      <div>
        <HubspotForm />
      </div>
    </IconContainer>
    <Line />
    <AddressContainer>
      <Container>
        <CopyRight>&copy; 2023 Uptick Inc. All rights reserved.</CopyRight>
        <TermsPrivacyContainer>
          <a href="https://uptick.co/privacy-policy ">
            <span>Privacy</span>
          </a>
          <Separator>|</Separator>
          <a href="https://uptick.co/terms-of-service">
            <span>Terms</span>
          </a>
        </TermsPrivacyContainer>
      </Container>
      <Container>
        <AddressIcon
          src="./assets/uptick_full_icon.png"
          style={{ marginBottom: '20px' }}
        />
        <AddressText>
          1120, Avenue of the Americas, New York, NY, 10036
        </AddressText>
      </Container>
    </AddressContainer>
  </BlockContainer>
);

export default NewFooter;
