/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import styled from 'styled-components';

declare const window: any;

const FormWrapper = styled.div`
  margin-left: -590px;
  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const HubspotForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);
    const label: any = document.createElement('label');
    label.innerText = 'Subscribe to Uptick’s newsletter';
    label.style.fontSize = '13px';
    label.style.color = 'white';
    label.style.marginRight = '10px';
    label.style.height = '40px';
    label.style.padding = '10px 0';
    label.style.width = '210px';
    label.style.fontWeight = '600';

    script.onload = () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '24482321',
        formId: 'e52d6ba5-0160-41e5-8803-0b768f8b64c2',
        target: '#hubspot-form',
        onFormReady: (form: any) => {
          form.insertAdjacentElement('afterbegin', label);
          form.querySelector('.hs-email').querySelector('label').style.display = 'none';
          form.querySelector('.hs-input').style.background = 'transparent';
          form.querySelector('.hs-input').style.color = 'white';
          form.querySelector('.input').style.marginRight = '25px';
          form.querySelector('.actions').style.margin = '0';
          form.querySelector('.actions').style.padding = '0';
          function handleWindowResize() {
            if (window.innerWidth <= 1024) {
              form.style.display = 'block';
            } else {
              form.style.display = 'flex';
            }
          }

          window.addEventListener('resize', handleWindowResize);
        },
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <FormWrapper
      id="hubspot-form"
      className="custom-hubspot-form"
    />
  );
};

export default HubspotForm;
