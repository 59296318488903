/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Tickers } from '../../ui';

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  color: white;
  background-color: #051025;
`;

const HeaderWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 14px 50px 20px;
`;

const Logo = styled(Image)`
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const Button = styled.div<{ background: string; }>`
  padding: 6px 39px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ background }) => background};
  
  @media (max-width: 768px) {
    padding: 4px 20px;
    font-size: 14px;
  }
`;

interface HeaderProps {
  onClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({ onClick }) => {
  const router = useRouter();

  return (
    <HeaderContainer>
      <Tickers />
      <HeaderWrapper>
        <Link href="/">
          <Logo onClick={onClick} src="/assets/mobileLogoWhite.png" width="30" height="26" alt="logo" />
        </Link>
        <ButtonsWrapper>
          <Button
            background="none"
            onClick={() => router.push('https://docs.uptick.co/')}
          >
            DOCUMENTATION
          </Button>
          <Button
            background="#55B1EC"
            onClick={() => router.push('/signin')}
          >
            LOGIN
          </Button>
        </ButtonsWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
